import React, { useEffect, useState, useCallback } from 'react';
import { Avatar, Name } from '@coinbase/onchainkit/identity';
import { BlueCreateWalletButton } from './BlueCreateWalletButton';
import { CoinbaseWalletSDK } from '@coinbase/wallet-sdk';

const sdk = new CoinbaseWalletSDK({
  appName: 'Fan Camp',
  appLogoUrl: 'https://imgur.com/a/IXXKvpD',
  appChainIds: [84532],
});

const provider = sdk.makeWeb3Provider({ options: 'all' });

const ConnectWalletButton = ({ setSigner }) => {
  const [address, setAddress] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  const handleSuccess = useCallback(
    async (address) => {
      try {
        setSigner(provider);
        setAddress(address);
        setIsConnected(true);
        console.log('Wallet created and connected:', address);
      } catch (error) {
        console.error('Error setting provider after wallet creation:', error);
      }
    },
    [setSigner]
  );

  const handleError = useCallback((error) => {
    console.error('Error creating wallet:', error);
  }, []);

  const disconnect = () => {
    setSigner(null);
    setAddress(null);
    setIsConnected(false);
  };

  useEffect(() => {
    provider.on('accountsChanged', (accounts) => {
      if (accounts.length === 0) {
        disconnect();
      } else {
        setAddress(accounts[0]);
      }
    });

    provider.on('disconnect', (error) => {
      console.error('Provider disconnected:', error);
      disconnect();
    });

    return () => {
      provider.removeAllListeners();
    };
  }, []);

  return (
    <div className="flex items-center space-x-4">
      {!isConnected ? (
        <BlueCreateWalletButton handleSuccess={handleSuccess} handleError={handleError} />
      ) : (
        <div className="flex items-center space-x-2">
          <Avatar address={address} />
          <Name address={address} showAddress />
          <button
            type="button"
            onClick={disconnect}
            className="px-4 py-2 bg-red-600 rounded-lg text-white text-lg"
          >
            Disconnect
          </button>
        </div>
      )}
    </div>
  );
};

export default ConnectWalletButton;
